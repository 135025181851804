<template>
  <div>
    <navigation-bar
      :background="'white'"
      :link="{ name: 'task-interactions', params: taskInteractionsParams }"
      label="Consigna"
    />
    <div class="container is-widescreen page-bottom-padding-extra">
      <div class="wrapper">
        <div class="columns is-centered">
          <div class="column standard-column">
            <div
              v-if="taskData !== null"
              class="response-section"
            >
              <response
                :response="response"
                :task-data="taskData"
                :display-borders="false"
                :display-comments="false"
                :display-response-images-description="true"
              />
            </div>
            <div class="comments-container">
              <comment
                v-for="(comment) in commentsData"
                :key="comment.id"
                :comment="comment"
                :display-participant-tags="!isParticipant"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <add-comment-bar
      v-if="taskData !== null && (isParticipant || !currentProjectStaffUserIsObserver)"
      :commented-content-identifier="response.identifier"
      :commented-content-app-label="'projects'"
      :commented-content-model="'taskresponse'"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import AddCommentBar from '@/components/comments/AddCommentBar.vue'
import Comment from '@/components/interactions/Comment.vue'
import NavigationBar from '@/components/NavigationBar'
import Response from '@/components/interactions/Response.vue'

export default {
  name: 'InteractionsResponseDetails',
  components: {
    AddCommentBar, Comment, NavigationBar, Response
  },
  computed: {
    ...mapGetters('Projects', ['currentProjectStaffUserIsObserver']),
    ...mapState('comments', ['commentsData']),
    ...mapState('interactionsInteractions', ['taskData']),
    taskInteractionsParams() {
      return {
        projectIdentifier: this.$route.params.projectIdentifier,
        taskIdentifier: this.$route.params.taskIdentifier
      }
    },
    response() {
      const responses = this.taskData ? this.taskData.taskResponses : []
      return responses.find((r) => r.identifier === this.$route.params.responseIdentifier)
    }
  }
}
</script>

<style lang="scss" scoped>
.response-section {
  background: #fff;
  margin-bottom: 30px;

  border: 1px solid $gray-light;
  box-sizing: border-box;
  box-shadow: 0px 4px 6px rgba(200, 200, 200, 0.24);
  border-radius: 6px;
}

.response-container {
  padding-left: 14px;
  padding-right: 14px;
}
</style>
