<template>
  <div class="general-container grey-background">
    <the-navbar />
    <response-details v-if="!isLoading" />
    <b-loading
      v-model="isLoading"
      :is-full-page="true"
      :can-cancel="false"
      class="fill-screen"
    />
  </div>
</template>

<script>
import ResponseDetails from '@/components/interactions/ResponseDetails.vue'
import TheNavbar from '@/components/TheNavbar.vue'

export default {
  name: 'ResponseInteractions',
  components: {
    ResponseDetails,
    TheNavbar
  },
  data() {
    return {
      isLoading: true
    }
  },
  async created() {
    // Fetch the response data
    const params = {
      activityIdentifier: this.$route.params.activityIdentifier,
      projectIdentifier: this.$route.params.projectIdentifier,
      responseIdentifier: this.$route.params.responseIdentifier,
      taskIdentifier: this.$route.params.taskIdentifier
    }

    await this.$store.dispatch('interactionsInteractions/getResponseInitialData', params)

    if (!this.isParticipant) {
      const success = await this.$store.dispatch(
        'staffParticipants/getParticipantsData',
        {
          projectIdentifier: this.$route.params.projectIdentifier
        }
      )

      if (success) this.isLoading = false
    } else {
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.general-container {
  .float-bottom-navbar {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 23px;
  }
}
</style>
